<template>
  <div>
    <multi-select-field
      :input="{ ...input, values: this.options }"
      v-model="model"
    />
    <v-form
      ref="optionInputField"
      class="d-flex flex-wrap flex-md-nowrap align-items-start th-mt-24"
    >
      <v-text-field
        outlined
        class="max-w-360 th-mr-4 th-mr-12-md"
        hide-details="auto"
        v-model="newOption"
        :rules="input.inputRules || []"
        :label="$translation.t(input.addLabel) || ''"
      ></v-text-field>
      <v-btn color="primary" outlined @click="addOption" class="th-mt-4">
        <v-icon>mdi-plus</v-icon>
        {{ $translation.t(input.optionButtonLabel || "Add option") }}
      </v-btn>
    </v-form>
    <p class="text-danger th-mt-12" v-if="error">
      {{ $translation.t(error) }}
    </p>
  </div>
</template>

<script>
import { isFunction } from "lodash";
import MultiSelectField from "./MultiSelectField";
export default {
  name: "AddOptionField",
  components: { MultiSelectField },
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: [Array, String],
  },
  data: () => ({
    model: [],
    options: [],
    newOption: "",
    selectAll: false,
    error: null,
  }),
  created() {
    this.model = this.value || [];

    let defaultOptions = this.input?.options || this.input?.values || []; //.filter((option) => this.model.indexOf(option) < 0);

    this.options = [
      ...defaultOptions,
      ...this.model.filter(
        (val) => (this.defaultOptions || [])?.indexOf(val) >= 0
      ),
    ];
  },
  methods: {
    addOption() {
      if (this.newOption && this.$refs["optionInputField"].validate()) {
        this.options.push(this.newOption);
        this.model.push(this.newOption);

        this.newOption = "";
      }
    },
    validate() {
      this.addOption();

      this.input.rules.forEach((rule) => {
        if (isFunction(rule)) {
          const error = rule(this.model);
          this.error = typeof error === "string" ? error : null;
        }
        if (this.error) return;
      });

      return !this.error;
    },
    translateLabel(label) {
      if (this.input.trans) this.$translation.t(label);

      return label;
    },
  },
  watch: {
    model: {
      handler: function (newList) {
        const currentValue = [...newList];

        if (this.newOption) currentValue.push(this.newOption);

        this.error = null;

        this.$emit("input", currentValue);
      },
      deep: true,
    },
    newOption(newValue) {
      const currentValue = [...this.model];

      if (newValue) currentValue.push(newValue);

      this.$emit("input", currentValue);
    },
  },
};
</script>

<style scoped></style>
